import React from 'react';
import { AppProps } from 'next/app';
import NProgress from 'nprogress';
import Router, { useRouter } from 'next/router';
import { appWithTranslation } from 'next-i18next';
import { ChakraProvider } from '@chakra-ui/react';
import StackedLayout from 'ui/mcng/@bit/matternet.shared.components.stacked-layout';
import { initLogRocket } from '@/src/lib/initLogRocket';
import { Provider } from 'next-auth/client';
import { ToastContainer } from 'react-toastify';
import AuthenticatedNavbar from '../components/AuthenticatedNavbar/AuthenticatedNavbar';
import theme from '../theme';
import NetworkDisconnectToast from '../components/NetworkDisconnectToast';
import 'react-toastify/dist/ReactToastify.min.css';
import '@/src/styles/global.css';
import 'mapbox-gl/dist/mapbox-gl.css';

// init sentry as a very first step
import '@/src/lib/initSentry';

/* eslint-disable prefer-destructuring */
// Figure out what's up with the audience
// const AUTH_ZERO_API_AUDIENCE = process.env.AUTH_ZERO_API_AUDIENCE;
/* eslint-enable prefer-destructuring */

initLogRocket();

Router.events.on('routeChangeStart', (url) => {
  // eslint-disable-next-line no-console
  console.log(`Loading: ${url}`);
  NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

function App({ Component, pageProps }: AppProps<{ session?: string }>) {
  const router = useRouter();

  // const [colorMode, setColorMode] = useState<IColorModeProvider['value']>(
  //   'light',
  // );

  // const AUTH_ZERO_REDIRECT_URI =
  //   typeof window === 'undefined' ? '' : window.location.origin;

  return (
    <>
      <Provider session={pageProps.session}>
        <ChakraProvider theme={theme}>
          <ToastContainer
            bodyStyle={{
              fontFamily: theme.fonts.body,
            }}
            limit={1}
            style={{ width: 600 }}
          />
          <NetworkDisconnectToast />
          {router.pathname === '/map' ? (
            <Component {...pageProps} />
          ) : (
            <StackedLayout>
              <AuthenticatedNavbar />
              <Component {...pageProps} />
            </StackedLayout>
          )}
        </ChakraProvider>
      </Provider>
    </>
  );
}

export default appWithTranslation(App);
